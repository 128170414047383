const Configs = function () {
  // usual
  this.IS_PROD = process.env.NODE_ENV === "production";
  this.APP_NAME = 'مجتمع فنتك السعودية';
  this.server_url = this.IS_PROD
    ? "https://fcapi.tsaol.net"
    : `http://localhost:7899`;

  this.client_url = this.IS_PROD
    ? "https://community.zid.sa"
    : "http://localhost:3000";

  // OAuth
  this.GOOGLE_CLIENT_ID = this.IS_PROD
    ? "800074486277-kelhmpo4khfnknvrb1i04kkeh1n5q5g5.apps.googleusercontent.com"
    : "73435292709-kf6njqgql7gdsdoin29tq54egppttif4.apps.googleusercontent.com";

  this.FACEBOOK_APP_ID = this.IS_PROD ? "337059397254409" : "1545113332307583";

  // this.
  this.TWITTER_API_KEY = this.IS_PROD
    ? "KJlKfYrDw8MgTfiNimfnYuSpy"
    : "dJvbjXIduNAOIc1okUkDiBL2B";
  this.TWITTER_API_SECRET = this.IS_PROD
    ? "zCT7qGAH5YMkIkUX8Cr8KJpDiJFQoX8BjpQXVxifictgPDwg8D"
    : "2rhbKG5wJYhlV1z9Ioo5p16hD4NS7loZQel4y8XPXhDMhsCzbn";

  this.TWITTER_CALLBACK_URL = this.IS_PROD 
    ? 'https://community.zid.sa/login'
    : 'http://localhost:3000/login'

};

let config = new Configs();
export default config;
