import React from 'react'

export default function Loader({ width, className }) {
   return (
      <React.Fragment>
         {/* <img src='/images/loader.gif' className={className} width={width || '20'} /> */}
         <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`mx-auto ${className}`}
            width={width || '25'}
            height={width || '25'}
            viewBox="0 0 38 38"
            stroke='#000'
          >
            <g fill="none" fill-rule="evenodd">
              <g transform="translate(1 1)" stroke-width="2">
                <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/>
                </path>
              </g>
            </g>
          </svg>
      </React.Fragment>
   )
}
