import React, { useContext, useState, useEffect } from "react";
// import { LogIn } from "react-feather";
import UserContext from "../../config/UserContext";
// import Dropdown from "./Dropdown";
import axios from "../../config/axios";
import { toast } from "react-hot-toast";
import NavBarLinksList from "./components/NavBarLinksList";
// import {Redirect} from 'react-router-dom'
import config from "../../config/config";
import getNewNotif from "../../events/getNewNotif";

// import resolveUserImg from '../../helpers/resolveUserImg';

export default function Navbar() {
  let { loadingUser, loggedInUser } = useContext(UserContext);
  let [userDropdownIsOpen, setUserDropdownIsOpen] = useState(false);
  let [newNotif, setNewNotif] = useState(false);

  // Get new notifications when user refresh or something
  useEffect(() => {
    (async function () {
      try {
        let res = await axios.get("/notifications/news");
        if (res.status === 200) {
          if (res.data.newNotifs) {
            setNewNotif(true);
          }
        }
      } catch (err) {
        setNewNotif(false);
      }
    })();
  }, []);

  // watch for new notifs
  useEffect(() => {
    getNewNotif(() => {
      setNewNotif(true)
    })
  }, [])

  async function handleLogout() {
    try {
      let logoutRes = await axios.get("/logout");
      if (logoutRes.status === 200) {
        window.location.href = config.client_url;
      }
    } catch (err) {
      toast.error("حدث خطأ ما ، يرجى إعادة المحاولة لاحقًا");
    }
  }

  return (
    <React.Fragment>
      {
        // overlay
        userDropdownIsOpen ?
         <span className='fixed top-0 left-0 w-full h-full z-50 block' onClick={() => setUserDropdownIsOpen(false)}></span> 
        : null
      }
      <nav
        id="mainNav"
        className="bg-white p-2 sticky w-full top-0 z-40 border-b border-gray-200 shadow-sm"
      >
        <div className="max-w-screen-xl mx-auto flex justify-between items-center">
          <div>
            <NavBarLinksList 
              userDropdownIsOpen={userDropdownIsOpen}
              loggedInUser={loggedInUser}
              loadingUser={loadingUser}
              newNotif={newNotif}
              toggleDropDown={() => setUserDropdownIsOpen(!userDropdownIsOpen)}
              onLogout={handleLogout}
            />
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}
