/**
 * Main App
 * All the routes are here
 */
import React, { useState, useEffect, lazy, Suspense, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useTour } from "@reactour/tour";

// Get logged in user provider
import { UserConsumer } from "./config/UserContext";

// Importing the navbar as its a common component
import Loader from "./components/loader";
import initSocketIo from "./helpers/initSocketIo";

// Pages
const QuestionsByTopic = lazy(() => import("./pages/topicQuestions"));
const CreateQuestion = lazy(() => import("./pages/createQuestion"));
const Notifications = lazy(() => import("./pages/notifications"));
const SingleQuestion = lazy(() => import("./pages/singleQuestion"));
const UserProfile = lazy(() => import("./pages/profile"));
const Search = lazy(() => import("./pages/search"));
const Login = lazy(() => import("./pages/auth/Login"));
const Join = lazy(() => import("./pages/auth/Join"));
const NotFound = lazy(() => import("./pages/error/NotFound"));
const UserSettings = lazy(() => import("./pages/userSettings"));
const VerifyAccount = lazy(() => import("./pages/verifyAccount"));
const Rules = lazy(() => import("./pages/static/Rules"));
const Privacy = lazy(() => import("./pages/static/Privacy"));
const Badges = lazy(() => import("./pages/static/Badges"));
const HowItWorks = lazy(() => import("./pages/static/HowItWorks"));

export default function Main() {
  const { loadingUser, loggedInUser } = useContext(UserConsumer);
  const wsIO = initSocketIo("");
  // localStorage.setItem('tour', 'done')

  useEffect(() => {
    wsIO.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
  }, []);

  return (
    <React.Fragment>
      <Suspense fallback={<Loader className="mt-32 block" />}>
        <Switch>
          {/* <Route exact path="/" component={Home} /> */}

          <Route
            exact
            path="/"
            component={(props) => {
              if (loadingUser) {
                return <Loader className="mt-32 mb-20" />;
              } else {
                if (loggedInUser) {
                  return <QuestionsByTopic match={props.match} />;
                } else if (!loggedInUser) {
                  return <Redirect to="/login" />;
                }
              }
            }}
          />

          <Route
            exact
            path="/topic/:topic_name"
            component={(props) => {
              if (loadingUser) {
                return <Loader className="mt-32 mb-20" />;
              } else {
                if (loggedInUser) {
                  return <QuestionsByTopic match={props.match} />;
                } else if (!loggedInUser) {
                  return <Redirect to="/login" />;
                }
              }
            }}
          />

          {/* for only logged in users */}
          <Route
            exact
            path="/question/create"
            component={() => {
              if (loadingUser) {
                return <Loader className="mt-32 mb-20" />;
              } else {
                if (loggedInUser) {
                  return <CreateQuestion />;
                } else if (!loggedInUser) {
                  return <Redirect to="/login" />;
                }
              }
            }}
          />

          {/* Search route */}
          <Route
            exact
            path="/search/:sort/:searchFor"
            component={() => {
              if (loadingUser) {
                return <Loader className="mt-32 mb-20" />;
              } else {
                if (loggedInUser) {
                  return <Search />;
                } else if (!loggedInUser) {
                  return <Redirect to="/login" />;
                }
              }
            }}
          />

          {/* users/consulters profile, will display the right design for the right user */}
          <Route
            exact
            path="/profile/:username"
            component={(props) => {
              if (loadingUser) {
                return <Loader className="mt-32 mb-20" />;
              } else {
                if (loggedInUser) {
                  return <UserProfile match={props.match} />;
                } else if (!loggedInUser) {
                  return <Redirect to="/login" />;
                }
              }
            }}
          />

          <Route
            exact
            path="/verify/:token"
            component={(props) => {
              if (loadingUser) {
                return <Loader className="mt-32 mb-20" />;
              } else {
                if (loggedInUser) {
                  if (!loggedInUser.me.verified) {
                    return <VerifyAccount props={props} />;
                  }
                  return <Redirect to="/" />;
                } else if (!loggedInUser) {
                  return <Redirect to="/login" />;
                }
              }
            }}
          />

          <Route
            exact
            path="/u/settings"
            component={() => {
              if (loadingUser) {
                return <Loader className="mt-32 mb-20" />;
              } else {
                if (loggedInUser) {
                  return <UserSettings />;
                } else if (!loggedInUser) {
                  return <Redirect to="/login" />;
                }
              }
            }}
          />

          {/* User notifications */}
          <Route
            exact
            path="/notifications"
            component={() => {
              if (loadingUser) {
                return <Loader className="mt-32 mb-20" />;
              } else {
                if (loggedInUser) {
                  return <Notifications />;
                } else if (!loggedInUser) {
                  return <Redirect to="/login" />;
                }
              }
            }}
          />

          {/* Single question */}
          <Route
            exact
            path="/q/:question_title"
            component={(props) => {
              if (loadingUser) {
                return <Loader className="mt-32 mb-20" />;
              } else {
                if (loggedInUser) {
                  return (
                    <SingleQuestion
                      location={props.location}
                      match={props.match}
                    />
                  );
                } else if (!loggedInUser) {
                  return <Redirect to="/login" />;
                }
              }
            }}
          />

          {/* Auth pages */}
          <Route
            exact
            path="/login"
            component={() => {
              if (loadingUser) {
                return <Loader className="mt-32 mb-20" />;
              } else {
                if (loggedInUser) {
                  return <Redirect to="/" />;
                } else {
                  return <Login />;
                }
              }
            }}
          />

          <Route
            exact
            path="/join"
            component={() => {
              if (loadingUser) {
                return <Loader className="mt-32 mb-20" />;
              } else {
                if (loggedInUser) {
                  return <Redirect to="/" />;
                } else if (!loggedInUser) {
                  return <Join />;
                }
              }
            }}
          />

          {/* Static pages like about us ... */}
          <Route exact path="/rules" component={Rules} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/badges" component={Badges} />
          <Route exact path="/how-it-works" component={HowItWorks} />

          {/* 404 page, this route must be the last one */}
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </React.Fragment>
  );
}
