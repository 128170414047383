import React, { useState, useEffect } from "react";

import axios from "./config/axios";
import Navbar from "./components/navbar";
import SecondNav from "./components/secondNav";
import { UserProvider } from "./config/UserContext";
import Main from "./Main";
import { BrowserRouter as Router } from "react-router-dom";
import TakeTour from "./components/takeTour";

export default function App() {
  const [loggedInUser, setloggedInUser] = useState({});
  const [loadingUser, setLoadingUser] = useState(true);

  // Get the logged in user
  useEffect(() => {
    (async function () {
      try {
        let { data } = await axios.get("/me");
        setloggedInUser(data);
      } catch (e) {
        setloggedInUser(false);
      }
      setLoadingUser(false);
    })();
  }, []);

  return (
    <UserProvider value={{ loadingUser, loggedInUser }}>
      <Router>
        <Navbar />
        <SecondNav />
        {!localStorage.getItem("tour") && !loadingUser && !!loggedInUser.me && (
          <TakeTour />
        )}
        <Main />
      </Router>
    </UserProvider>
  );
}
