import React from "react";
import { Link } from "react-router-dom";

export default function SecondNav() {
  return (
    <div className="bg-primaryColor px-2 py-2 shadow">
      <div className="max-w-screen-xl flex items-center justify-between mx-auto flex-wrap">
        <Link to="/" className="flex items-center">
          {/* <span className="ml-1 align-bottom inline-block md:text-lg text-zgray">مجتمع</span> */}
          <img
            src="https://fintechsaudi.com/wp-content/uploads/2020/06/logo.png"
            alt="Zid Community"
            className="h-8 w-auto"
          />
        </Link>
        <div className="flex items-center text-center py-2 overflow-x-auto whitespace-nowrap">
          <a
            target="_blank"
            rel="noreferrer"
            className="mx-2 text-white hover:text-secondColor font-semibold"
            href="https://fintechsaudi.com/قصتنا/?lang=ar"
          >
            قصتنا
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="mx-2 text-white hover:text-secondColor font-semibold"
            href="https://fintechsaudi.com/directory/?lang=ar"
          >
            دليل القطاع
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="mx-2 text-white hover:text-secondColor font-semibold"
            href="https://fintechsaudi.com/المبادرات/?lang=ar"
          >
            المبادرات
          </a>
        </div>
      </div>
    </div>
  );
}
