import { useTour } from "@reactour/tour";
import React, { useState } from "react";
import TakeTourModal from "../modals/TakeTourModal";

export default function TakeTour() {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { setIsOpen } = useTour();

  const onContinue = () => {
    localStorage.setItem("tour", "done");
    setIsOpen(true);
    setIsModalOpen(false);
  };

  return (
    <div>
      {isModalOpen && (
        <TakeTourModal onContinue={onContinue} closeModal={() => false} />
      )}
    </div>
  );
}
