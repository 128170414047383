import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { useState } from 'react';

function Tip({content, rest, children}){
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Tippy
      placement="top"
      // touch={false}
      hideOnClick="toggle"
      visible={isOpen}
      arrow={true}
      arrowType={'round'}
      content={content}
      // onTrigger={() => setIsOpen(true)}
    >
    <div
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onFocus={() => setIsOpen(true)}
      onBlur={() => setIsOpen(false)}
    >
      {children}
    </div>
  </Tippy>
  );
};

export default Tip;
