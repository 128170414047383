import React from "react";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "nice-react-modal";

export default function TakeTourModal({ closeModal, onContinue }) {
  const tourDone = () => {
    localStorage.setItem("tour", "done");
  };

  return (
    <React.Fragment>
      <Modal onClose={closeModal}>
        <ModalTitle> اهلا و سهلا  🎉 </ModalTitle>
        <ModalBody>
          <p className="text-lg">
            مرحبًا بك في مجتمع فنتك السعودية، دعنا نأخذك في جولة لفهم كيفية عمل
            المجتمع
          </p>
        </ModalBody>

        <ModalFooter>
          <span
            onClick={onContinue}
            class="block w-32 cursor-pointer mx-auto text-center bg-primaryColor hover:opacity-80 p-2 px-3 rounded-full text-white focus:outline-none"
          >
            واصل
          </span>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
