import { io } from 'socket.io-client';
import config from '../config/config';

const nsps = ['', 'notifs']

function initSocketIo(nsp, opts){
  const options = !opts ? {} : opts;
  if(nsps.indexOf(nsp) === -1){
    new Error(`Namespace must be in ${nsps.join(' | ')}`)
  }
  const socketIo = io(`${config.server_url}/${nsp}`, {
    transports: ['websocket'],
    withCredentials: true, 
    autoConnect: true,
    reconnection: true,
    ...options
  }).connect();

  return socketIo;
}


export default initSocketIo;
