import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/tailwind.css";
import "./assets/css/custom.css";
import App from "./App";
import { ToastContainer } from "react-toastify";
import { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.min.css";
import { X } from "react-feather";
import { TourProvider } from "@reactour/tour";
import steps from "./config/tourSteps";

ReactDOM.render(
  <React.Fragment>
    <ToastContainer
      hideProgressBar
      rtl
      closeButton={<X size={20} />}
      position="top-center"
    />
    <Toaster />
    <TourProvider
      disableDotsNavigation={true}
      styles={{
        dot: (props, st) => {
          return { ...props, background: "rgba(0,0,0,0.25)" };
        }
      }}
      showBadge={false}
      defaultOpen={false}
      steps={steps}
    >
      <App />
    </TourProvider>
  </React.Fragment>,
  document.getElementById("root")
);
