
const steps = [
  {
    selector: '[data-tour="question"]',
    content: "قائمة بآخر الأسئلة المنشورة على المجتمع",
  },
  {
    selector: '[data-tour="question-asker"]',
    content: "السائل, اضغط على اسمه لزيارة صفحته الشخصية",
  },
  {
    selector: '[data-tour="question-topic"]',
    content: "موضوع السؤال",
  },
  {
    selector: '[data-tour="navigate-topics"]',
    content: "انتقل إلى مواضيع محددة",
  },
  {
    selector: '[data-tour="add-question"]',
    content: "أضف سؤالك",
  },
];

export default steps
