import React from "react";
import Dropdown from "./Dropdown";
import Icon from "@hackclub/icons";
// import config from "../../../config/config";
import { Link } from "react-router-dom";
import Tip from "../../tip";

export default function NavBarLinksList({
  userDropdownIsOpen,
  loggedInUser,
  loadingUser,
  onLogout,
  toggleDropDown,
  newNotif,
}) {
  return (
    <React.Fragment>
      <ul className="flex items-center">
        {loadingUser ? (
          <React.Fragment>
            <li className="skeleton-shimmer w-8 h-8 flex rounded-full mx-2"></li>
            <li className="skeleton-shimmer w-8 h-8 flex rounded-full mx-2"></li>
          </React.Fragment>
        ) : !loggedInUser.me ? (
          <React.Fragment>
            {/* Login */}
            <Tip content="تسجيل الدخول">
              <li className="ml-4 bg-gray-200 relative text-gray-700 p-1 rounded-full hover:text-gray-900">
                <a href="/login" className="flex">
                  <Icon glyph="door-enter" size={30} />
                </a>
              </li>
            </Tip>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <React.Fragment>
              {/* add question icon */}
              <Tip content="أضف سؤال">
                <li data-tour="add-question" className=" ml-4 bg-gray-200 relative text-gray-700 p-1 rounded-full hover:text-gray-900">
                  <Link to="/question/create" className="flex">
                    <Icon glyph="plus" size={30} />
                  </Link>
                </li>
              </Tip>

              {/* notification icon */}
              <Tip content="إشعارات">
                <li className="ml-4 bg-gray-200 relative text-gray-700 p-1 rounded-full hover:text-gray-900">
                  <Link to="/notifications" className="flex">
                    {/* <Bell size={22} /> */}
                    <Icon glyph="notification" size={30} />
                  </Link>
                  {newNotif ? (
                    <div id="new_notif">
                      <span
                        className="absolute border-1 border-white flex items-center justify-center top-0 right-0 rounded-full bg-red-500 text-gray-100 font-bold"
                        style={{
                          width: "10px",
                          height: "10px",
                          fontSize: "10px",
                        }}
                      ></span>
                    </div>
                  ) : null}
                </li>
              </Tip>
            </React.Fragment>

            {/* Profile icon */}
            <Tip content="ملفك الشخصي">
              <li className="relative" id="mainNavProfileIcon">
                <div
                  onClick={toggleDropDown}
                  className="text-sm font-bold leading-none cursor-pointer bg-gray-200 relative text-gray-700 p-1 rounded-full hover:text-gray-900"
                >
                  <Icon glyph="profile" size={30} />
                </div>
                {/* {{--  User dropdown menu  --}} */}
                <Dropdown
                  isOpen={userDropdownIsOpen}
                  style={{ top: "110%" }}
                  className="border-t-4 border-primaryColor w-40"
                >
                  <div className="hover:bg-gray-200 text-gray-800">
                    <a
                      href={`/profile/${loggedInUser.me.username}`}
                      className="block py-2 px-5"
                    >
                      <Icon glyph="person" className="inline ml-2 text-gray-600" size={23} />
                      الملف
                    </a>
                  </div>
                  <div className="hover:bg-gray-200 text-gray-800">
                    <a
                      href="/u/settings"
                      className="block py-2 px-5"
                    >
                      <Icon
                        glyph="settings"
                        className="inline ml-2 text-gray-600"
                        size={23}
                      />
                      الإعدادات
                    </a>
                  </div>
                  <hr />
                  <div className="hover:bg-gray-200 text-gray-600 hover:text-red-500">
                    <button
                      onClick={onLogout}
                      className="block py-2 px-5 w-full text-right"
                    >
                      <Icon glyph="door-leave" className="inline ml-2" size={23} />
                      تسجيل خروج
                    </button>
                  </div>
                </Dropdown>
              </li>
            </Tip>
          </React.Fragment>
        )}
      </ul>
    </React.Fragment>
  );
}
